"use strict";

(function ($) {

$(document).ready(function() {

  var to = null, $scroller = null;

  class Anima {
    constructor() {
      this.kind = 'Anima';
      this.attachListeners();
    }
    attachListeners() {
      to = setTimeout(() => { this.scrollToBottom(); }, 5000);
      $scroller = ($('#scrollable-content').css('overflow') == 'scroll')  ? $('#scrollable-content') : $(window);
      $scroller.bind('scroll', () => { this.clearAutoscroll(); });
    }
    clearAutoscroll() {
      if (to != null) {
        clearTimeout(to);
        $scroller.unbind('scroll');
      }
    }
    scrollToBottom() {
      if ($('#scrollable-content').css('overflow') == 'scroll') {
        var y = $('#scrollable-content').height() * 2;
        var speed = Math.floor(Math.abs($('#scrollable-content').scrollTop() - y/1.5));
        $('#scrollable-content').animate({
            scrollTop: y
        }, speed);
      }
      else {
        var scrollingElement = (document.scrollingElement || document.body);
        var speed = Math.floor(Math.abs(scrollingElement.scrollTop - scrollingElement.scrollHeight)/2);
        $(scrollingElement).animate({
            scrollTop: scrollingElement.scrollHeight
        }, speed);
      }
    }
  }

  new Anima();
  
});

})(jQuery);
